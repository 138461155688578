import { HCPIdentifierType } from '../identifiers';

// Field values are the values that are stored in the form state.
// They are similar but not always exactly equivalent to the values that are sent to the backend and to mixpanel.
// Values sent to the backend and mixpanel are defined in @src/types.ts.

// =====================
// HCP or Student Fields

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
type Month = (typeof MONTHS)[number];

export const YEARS = Array.from(
  { length: 10 },
  (_, i) => `${new Date().getFullYear() + i}`
);
type Year = (typeof YEARS)[number];

export interface RawFile {
  name: string;
  base64: string;
}

export interface HCPOrStudentFieldValues {
  name: string;
  occupation: string;
  otherOccupation: string;
  specialty: string;
  identifier: HCPIdentifierType;
  identifierValue: string;
  medicalSchool: string;
  expectedYearOfGraduation: Year;
  expectedMonthOfGraduationString: Month;
  files?: File[];
  rawFiles?: RawFile[];
  referrer: string;
  inviteCode: string;
  hasConsentedToPolicies: boolean;
}

// =====================
// Patients/caregivers Fields

export const PT_SIGNUP_ROLES = ['Patient', 'Caregiver'];
type Role = (typeof PT_SIGNUP_ROLES)[number];

const OTHER_PATIENT_COMMUNITY_SUBTYPE = 'Other/Prefer not to say';
export const PATIENT_COMMUNITIES = {
  'Anxiety Disorder': [
    'Generalized Anxiety Disorder',
    'Panic Disorder',
    'Social Anxiety Disorder',
    'Specific Phobias',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Asthma: [
    'Allergic Asthma',
    'Non-Allergic Asthma',
    'Exercise-Induced Asthma',
    'Occupational Asthma',
    'Nocturnal Asthma',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Autism Spectrum Disorder': [],
  'Bipolar Disorder': [
    'Bipolar I Disorder',
    'Bipolar II Disorder',
    'Cyclothymic Disorder',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Breast Cancer': [
    'Ductal Carcinoma In Situ (DCIS)',
    'Invasive Ductal Carcinoma (IDC)',
    'Invasive Lobular Carcinoma (ILC)',
    'Triple-Negative Breast Cancer',
    'HER2-Positive Breast Cancer',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'COVID-19': [],
  'Chronic Kidney Disease': [
    'Stage 1 (Normal or High GFR)',
    'Stage 2 (Mild CKD)',
    'Stage 3 (Moderate CKD)',
    'Stage 4 (Severe CKD)',
    'Stage 5 (End-Stage Renal Disease)',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Chronic Obstructive Pulmonary Disease (COPD)': [
    'Chronic Bronchitis',
    'Emphysema',
    'Refractory Asthma',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Colorectal Cancer': [
    'Adenocarcinoma',
    'Colon Cancer',
    'Rectal Cancer',
    'Familial Adenomatous Polyposis (FAP)',
    'Lynch Syndrome (HNPCC)',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Dementia: [
    "Alzheimer's Disease",
    'Vascular Dementia',
    'Lewy Body Dementia',
    'Frontotemporal Dementia',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Depression: [
    'Major Depressive Disorder',
    'Persistent Depressive Disorder (Dysthymia)',
    'Seasonal Affective Disorder',
    'Postpartum Depression',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Diabetes: [
    'Type 1 Diabetes',
    'Type 2 Diabetes',
    'Gestational Diabetes',
    'Prediabetes',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Endometriosis: [],
  Epilepsy: [
    'Generalized Seizures',
    'Focal Seizures',
    'Absence Seizures',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Fibromyalgia: [],
  'Genetic Disease': [
    'Cystic Fibrosis',
    'Down Syndrome',
    'Sickle Cell Disease',
    'Hemophilia',
    'Muscular Dystrophy',
    'Neurofibromatosis',
    'Thalassemia',
    'Phenylketonuria (PKU)',
    "Huntington's Disease",
    'Tay-Sachs Disease',
    'Gaucher Disease',
    'Marfan Syndrome',
    'Fragile X Syndrome',
    'Alpha-1 Antitrypsin Deficiency',
    'Other/Prefer not to say',
  ],
  'HIV/AIDS': [],
  'Heart Disease': [
    'Coronary Artery Disease',
    'Heart Failure',
    'Arrhythmias',
    'Cardiomyopathy',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Hepatitis: [
    'Hepatitis A',
    'Hepatitis B',
    'Hepatitis C',
    'Autoimmune Hepatitis',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Hypertension: [
    'Essential (Primary) Hypertension',
    'Secondary Hypertension',
    'Isolated Systolic Hypertension',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Inflammatory Bowel Disease': [
    "Crohn's Disease",
    'Ulcerative Colitis',
    'Indeterminate Colitis',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Irritable Bowel Syndrome (IBS)': [
    'IBS with Constipation (IBS-C)',
    'IBS with Diarrhea (IBS-D)',
    'Mixed IBS (IBS-M)',
    'Unsubtyped IBS',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Leukemia: [
    'Acute Lymphoblastic Leukemia (ALL)',
    'Acute Myeloid Leukemia (AML)',
    'Chronic Lymphocytic Leukemia (CLL)',
    'Chronic Myeloid Leukemia (CML)',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Lung Cancer': [
    'Non-Small Cell Lung Cancer',
    'Lung Adenocarcinoma',
    'Squamous Cell Lung Carcinoma',
    'Large Cell Carcinoma',
    'Small Cell Lung Cancer',
    'Lung Carcinoid Tumor',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Lupus: [
    'Systemic Lupus Erythematosus (SLE)',
    'Cutaneous Lupus',
    'Drug-Induced Lupus',
    'Neonatal Lupus',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Lyme Disease': [],
  Lymphoma: [
    'Hodgkin Lymphoma',
    'Non-Hodgkin Lymphoma',
    'B-cell Lymphomas',
    'T-cell Lymphomas',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Menopause: [],
  Migraine: [
    'Migraine without Aura',
    'Migraine with Aura',
    'Chronic Migraine',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Multiple Myeloma': [
    'IgG Myeloma',
    'IgA Myeloma',
    'Light Chain Myeloma',
    'Non-secretory Myeloma',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Multiple Sclerosis': [
    'Relapsing-Remitting MS',
    'Secondary Progressive MS',
    'Primary Progressive MS',
    'Progressive-Relapsing MS',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Myasthenia Gravis': [
    'Ocular Myasthenia Gravis',
    'Generalized Myasthenia Gravis',
    'Congenital Myasthenia Gravis',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Obesity: [],
  Osteoarthritis: [
    'Knee Osteoarthritis',
    'Hip Osteoarthritis',
    'Hand Osteoarthritis',
    'Spine Osteoarthritis',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Osteoporosis: [],
  'Ovarian Cancer': [
    'Epithelial Tumors',
    'Germ Cell Tumors',
    'Stromal Tumors',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Pancreatic Cancer': [
    'Exocrine Pancreatic Cancer',
    'Pancreatic Neuroendocrine Tumors',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  "Parkinson's Disease": [],
  'Polycystic Ovary Syndrome (PCOS)': [],
  'Prostate Cancer': [
    'Adenocarcinoma',
    'Small Cell Carcinoma',
    'Neuroendocrine Tumors',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Psoriasis: [
    'Plaque Psoriasis',
    'Guttate Psoriasis',
    'Inverse Psoriasis',
    'Pustular Psoriasis',
    'Erythrodermic Psoriasis',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Rheumatoid Arthritis': [],
  Schizophrenia: [],
  'Sleep Apnea': [
    'Obstructive Sleep Apnea',
    'Central Sleep Apnea',
    'Complex Sleep Apnea Syndrome',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  Stroke: [
    'Ischemic Stroke',
    'Hemorrhagic Stroke',
    'Transient Ischemic Attack (TIA)',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Substance Use Disorder': [
    'Alcohol Use Disorder',
    'Opioid Use Disorder',
    'Tobacco Use Disorder',
    'Stimulant Use Disorder',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Thyroid Cancer': [
    'Papillary Thyroid Cancer',
    'Follicular Thyroid Cancer',
    'Medullary Thyroid Cancer',
    'Anaplastic Thyroid Cancer',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
  'Thyroid Disorder': [
    'Hypothyroidism',
    'Hyperthyroidism',
    "Hashimoto's Thyroiditis",
    "Graves' Disease",
    'Thyroid Nodules',
    OTHER_PATIENT_COMMUNITY_SUBTYPE,
  ],
};
export type PatientCommunity = keyof typeof PATIENT_COMMUNITIES;

// Utility type to get the literal string values of the disease subtypes for a given patient community
type PatientCommunitySubtype<T extends PatientCommunity> =
  (typeof PATIENT_COMMUNITIES)[T][number];

export interface PatientFieldValues<
  T extends PatientCommunity = PatientCommunity
> {
  name: string;
  role: Role;
  patientCommunity: T;
  patientCommunitySubtype: PatientCommunitySubtype<T>; // Dynamic PatientCommunitySubtype based on the selected patientCommunity
  askedDoctor: boolean;
  referrer: string;
  hasConsentedToPolicies: boolean;
}
