import { SignupCompletedEventProps, TrackMixpanelEvent } from '@xyla/analytics';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { HOW_DID_YOU_HEAR, OCCUPATIONS, SPECIALTIES } from '../constants';
import { HCP_IDENTIFIERS, HCPIdentifierType } from '../identifiers';
import { HCP_REGISTRATION_TYPE, STUDENT_REGISTRATION_TYPE } from '../types';
import { HCPOrStudentUserDataPayload, UserInfo } from '../users/types';
import { HCPOrStudentFieldValues, MONTHS, RawFile, YEARS } from './fieldValues';
import { FieldMetadata, UploadType } from './types';
import { SendMixpanelSignupEventPropsWithReferrer } from './useOnboardingBase';
import {
  constructRulesFromFieldMetadata,
  listToOptions,
  validateNotEmpty,
} from './utils';
import { CountryTier, getCountryTier } from '@xyla/util';

type UploadFileToGcs = (props: {
  file: File;
  uploadType: UploadType;
  uploadFileName: string;
}) => Promise<void>;

export type UploadRawFileToGcs = (props: {
  file: RawFile;
  uploadType: UploadType;
  uploadFileName: string;
}) => Promise<void>;

interface UseOnboardingHCPOrStudentProps {
  userInfo: UserInfo;
  trackMixpanelEvent: TrackMixpanelEvent;
  uploadFileToGcs?: UploadFileToGcs;
  uploadRawFileToGcs?: UploadRawFileToGcs;
  trackNPIConversion?: (auth0Id: string) => void;
  country: string;
}

export function useOnboardingHCPOrStudent({
  userInfo,
  trackMixpanelEvent,
  uploadFileToGcs,
  uploadRawFileToGcs,
  trackNPIConversion,
  country,
}: UseOnboardingHCPOrStudentProps) {
  const countryTier = getCountryTier(country);

  // Set default values based on the user metadata
  const defaultOccupationString = useMemo(
    () => userInfo?.occupation ?? '',
    [userInfo]
  );
  const defaultOccupationDropdownValue = useMemo(
    () =>
      OCCUPATIONS.includes(defaultOccupationString)
        ? defaultOccupationString
        : defaultOccupationString
        ? 'Other'
        : '',
    [defaultOccupationString]
  );
  const defaultOtherOccupationValue = useMemo(
    () =>
      defaultOccupationDropdownValue === 'Other' ? defaultOccupationString : '',
    [defaultOccupationDropdownValue, defaultOccupationString]
  );

  const defaultSpecialtyValue = useMemo(
    () => userInfo?.specialty ?? '',
    [userInfo]
  );

  const defaultReferrerDropdownValue = useMemo(
    () => HOW_DID_YOU_HEAR.find((value) => value === userInfo?.referrer) ?? '',
    [userInfo]
  );

  // Filter down identifier options.
  // An identifier `I` is valid for a user coming from country `C`, if one
  // of the following is true:
  // - `C` is in the `includedCountries` list for `I`,
  // - `I` does not have an `includedCountries` list, AND `C` is not in the
  //   `excludedCountries` list of `I`,
  // - The identifier is already set to `I`.
  const hcpIdentifierOptions = useMemo(() => {
    let optionsToProcess = HCP_IDENTIFIERS;

    // If country is unknown, only allow file uploads
    if (!country) {
      const fileUpload = HCP_IDENTIFIERS.find(
        (hcp_id) => hcp_id.type === 'fileUpload'
      );
      if (!fileUpload) {
        throw new Error('unreachable');
      }
      optionsToProcess = [fileUpload];
    }

    return optionsToProcess
      .filter(
        (identifier) =>
          // TODO(andy): Audit this use case, then disable it.
          (userInfo?.hcpIdentifierType &&
            userInfo?.hcpIdentifierType === identifier.type) ||
          // end todo
          (identifier.includedCountries &&
            identifier.includedCountries.includes(country)) ||
          (identifier.excludedCountries &&
            !identifier.excludedCountries.includes(country))
      )
      .map((identifier) => ({
        label: identifier.label,
        value: identifier.type,
      }));
  }, [country, userInfo]);

  // Set the default identifier type either to the one that was already set (if exists),
  // OR to the first item in the list.
  const [defaultIdentifier, defaultIdentifierValue]: [
    HCPIdentifierType,
    string
  ] = useMemo(() => {
    if (userInfo && userInfo.hcpIdentifierType) {
      return [userInfo.hcpIdentifierType, userInfo.hcpIdentifierValue ?? ''];
    } else if (hcpIdentifierOptions.length > 0) {
      // If the default type is not set, we also do not use the default value,
      // even if it is set.
      return [hcpIdentifierOptions[0].value, ''];
    } else {
      // fallback to file upload if not other appropriate identifier was found.
      return ['fileUpload' as const, ''];
    }
  }, [userInfo, hcpIdentifierOptions]);

  const defaultMedicalSchool = useMemo(
    () => userInfo?.medicalSchool ?? '',
    [userInfo]
  );

  const defaultExpectedMonthOfGraduationString = useMemo(
    () =>
      MONTHS.find(
        (month) => month === userInfo?.expectedMonthOfGraduationString
      ) ?? '',
    [userInfo]
  );

  const defaultExpectedYearOfGraduation = useMemo(
    () => userInfo?.expectedYearOfGraduation?.toString() ?? '',
    [userInfo]
  );

  const defaultInviteCode = useMemo(
    () => userInfo?.inviteCode ?? '',
    [userInfo]
  );

  const defaultValues = useMemo(
    () => ({
      name: userInfo?.name ?? '',
      occupation: defaultOccupationDropdownValue,
      otherOccupation: defaultOtherOccupationValue,
      specialty: defaultSpecialtyValue,
      identifier: defaultIdentifier,
      identifierValue: defaultIdentifierValue,
      referrer: defaultReferrerDropdownValue,
      medicalSchool: defaultMedicalSchool,
      expectedMonthOfGraduationString: defaultExpectedMonthOfGraduationString,
      expectedYearOfGraduation: defaultExpectedYearOfGraduation,
      inviteCode: defaultInviteCode,
    }),
    [
      userInfo,
      defaultOccupationDropdownValue,
      defaultOtherOccupationValue,
      defaultSpecialtyValue,
      defaultIdentifier,
      defaultIdentifierValue,
      defaultReferrerDropdownValue,
      defaultMedicalSchool,
      defaultExpectedMonthOfGraduationString,
      defaultExpectedYearOfGraduation,
      defaultInviteCode,
    ]
  );

  const form = useForm<HCPOrStudentFieldValues>({
    defaultValues,
  });

  // If the source data every changes from outside of here, reset the form.
  // This is needed because in the app, we don't start off with the data from a "server"
  // the way we do on the web.
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const selectedOccupation = form.watch('occupation');

  const npiFromDb = useMemo(() => userInfo?.userDataFromDB?.db_npi, [userInfo]);

  const constructUserDataPayload = useCallback(
    (data: HCPOrStudentFieldValues) => {
      // Determine additional fields based on occupation
      let additionalFields = {};
      switch (data.occupation) {
        case 'Medical Student':
          additionalFields = {
            medicalSchool: data.medicalSchool,
            expectedYearOfGraduation: data.expectedYearOfGraduation,
            expectedMonthOfGraduationString:
              data.expectedMonthOfGraduationString,
            registrationType: STUDENT_REGISTRATION_TYPE,
            hasVerificationFiles:
              (data.files ?? []).length + (data.rawFiles ?? []).length > 0,
          };
          break;
        case 'Physician':
          additionalFields = {
            specialty: data.specialty,
          };
        // NB(Oliver): We specifically fall-through since the Physician case has all the default additional fields also.
        // fallthrough (keep this line for linting)
        default:
          additionalFields = {
            hcpIdentifierType: data.identifier,
            hcpIdentifierValue: data.identifierValue.trim(),
            hasVerificationFiles:
              (data.files ?? []).length + (data.rawFiles ?? []).length > 0,
            ...additionalFields,
            registrationType: HCP_REGISTRATION_TYPE,
          };
          break;
      }

      const body: HCPOrStudentUserDataPayload = {
        name: data.name.trim(),
        occupation:
          data.occupation === 'Other'
            ? data.otherOccupation.trim()
            : data.occupation,
        referrer: data.referrer,
        inviteCode: data.inviteCode,
        hasConsentedToPolicies: data.hasConsentedToPolicies,
        ...additionalFields,
      };

      return body;
    },
    []
  );

  const uploadFiles = useCallback(
    async (data: HCPOrStudentFieldValues) => {
      const uploadType =
        selectedOccupation === 'Medical Student'
          ? UploadType.MED_STUDENT_VERIFICATION
          : UploadType.HCP_VERIFICATION;

      // Web uploads
      const files: File[] = data.files ?? [];
      await Promise.all(
        files?.map(async (file: File, index: number) => {
          await uploadFileToGcs?.({
            file: file,
            uploadType,
            uploadFileName: `file_${index}_${file.name}`,
          });
        })
      );

      // Mobile uploads
      const rawFiles: RawFile[] = data.rawFiles ?? [];
      await Promise.all(
        rawFiles?.map(async (rawFile: RawFile, index: number) => {
          await uploadRawFileToGcs?.({
            file: rawFile,
            uploadType,
            uploadFileName: `rawfile_${index}_${rawFile.name}`,
          });
        })
      );
    },
    [uploadFileToGcs, uploadRawFileToGcs, selectedOccupation]
  );

  const sendMixpanelSignupEventWithReferringPage = useCallback(
    async ({
      body,
      referringPage,
      accessLevelToGive,
      hasProcessedUser,
    }: SendMixpanelSignupEventPropsWithReferrer<HCPOrStudentUserDataPayload>) => {
      await trackMixpanelEvent?.('signup_completed_trusted', {
        // props that are not sent on every (other) event as well
        specialty: body.specialty,
        medicalSchool: body.medicalSchool,
        hasExpectedMonthOfGraduation:
          Boolean(body.expectedMonthOfGraduationString) &&
          Boolean(body.expectedYearOfGraduation),

        // explicitly overwrite props that are sent on every event
        auth0Occupation: body.occupation,
        auth0NPIFromDB: npiFromDb,
        auth0AccessLevel: accessLevelToGive ?? undefined,
        auth0RegistrationType: body.registrationType,
        auth0HCPIdentifierType: body.hcpIdentifierType,
        auth0HCPIdentifierValue: body.hcpIdentifierValue,

        // misc/accounting
        referring_page: referringPage,
        skippingMetadataBecauseKnown: false,
        hasProcessedUser,
        referrer: body.referrer,
        inviteCode: body.inviteCode,
        prioritizeEventAuth0Data: true, // Need this here because we have new knowledge that is not baking into the session yet
        hasConsentedToPolicies: body.hasConsentedToPolicies,
      } satisfies SignupCompletedEventProps);

      // Track conversion for reddit API
      if (
        (body.hcpIdentifierType === 'npi' && body.hcpIdentifierValue) ||
        npiFromDb
      ) {
        trackNPIConversion?.(userInfo.id);
      }
    },
    [userInfo, npiFromDb, trackMixpanelEvent, trackNPIConversion]
  );

  // Verification Box Text
  const hcpVerificationExplanationText = useMemo(() => {
    if (selectedOccupation === 'Medical Student') {
      return 'Provisional access will be granted for 48 hours, and expanded access will be granted after verification.';
    }

    if (countryTier === CountryTier.TIER_1) {
      return 'OpenEvidence is free for verified health care professionals (HCPs) in the United States. If you are eligible for access, please enter your National Provider Identifier (NPI).';
    } else {
      return 'OpenEvidence is free for verified health care professionals (HCPs). If you are eligible for access, please provide your official HCP identification number.';
    }
  }, [selectedOccupation, countryTier]);

  const hcpVerificationHeader = useMemo(
    () =>
      selectedOccupation === 'Medical Student'
        ? 'Verify Your Student Status'
        : 'Verify Your Credentials',
    [selectedOccupation]
  );

  const hcpVerificationHelpIfNoIDText = useMemo(
    () =>
      countryTier === CountryTier.TIER_1 ? (
        <span>
          If you are an HCP without an NPI, please see{' '}
          <a
            href='https://www.cms.gov/medicare/provider-enrollment-and-certification/medicareprovidersupenroll/downloads/enrollmentsheet_wwwwh.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            this government resource
          </a>{' '}
          to learn how to obtain one.
        </span>
      ) : null,
    [countryTier]
  );

  const verificationProofTitle = useMemo(
    () =>
      selectedOccupation === 'Medical Student'
        ? 'Upload proof of medical student status'
        : 'Upload proof of occupational status',
    [selectedOccupation]
  );

  const verificationProofOptions = useMemo(
    () =>
      selectedOccupation === 'Medical Student'
        ? [
            'A picture of an ID badge bearing name and medical trainee status',
            'A transcript showing course load for the current school year',
            'A signed, dated letter by a faculty member',
          ]
        : [
            'A picture of an ID badge bearing name and professional status.',
            'A copy of board certification documents or verification printout from a recognized certifying body.',
          ],
    [selectedOccupation]
  );

  const hasProcessedUser = useMemo(
    () => userInfo?.hasProcessedUser ?? false,
    [userInfo]
  );

  // Field metadata for all fields
  const selectedIdentifierType = form.watch('identifier');
  const selectedIdentifier = useMemo(
    () =>
      HCP_IDENTIFIERS.find(
        (_identifier) => _identifier.type === selectedIdentifierType
      ),
    [selectedIdentifierType]
  );
  const validateIdentifierValue = useCallback(
    (value: string) => {
      // If the identifier starts with 'XA-', it's an access code and we'll check it on the server
      if (value.startsWith('XA-')) {
        return true;
      }

      // TODO: We still need to add the checksums to improve the validation for non-NPI identifiers
      const validateOutput = selectedIdentifier?.validate?.(value.trim());
      const isValid = typeof validateOutput === 'string';

      // The output of this function is different: a string represents the error when invalid
      if (isValid) {
        return true;
      }
      return selectedIdentifier?.message ?? false;
    },
    [selectedIdentifier]
  );

  const fileUploadErrorMessage = useMemo(
    () =>
      selectedOccupation === 'Medical Student'
        ? 'Please upload proof of student status'
        : 'Please upload proof of occupational status',
    [selectedOccupation]
  );

  const allFieldMetadata: Record<
    keyof HCPOrStudentFieldValues,
    FieldMetadata<HCPOrStudentFieldValues>
  > = useMemo(
    () =>
      ({
        name: {
          label: 'Name',
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please enter your name',
            validate: validateNotEmpty,
          }),
        },
        occupation: {
          label: 'Occupation',
          options: listToOptions(OCCUPATIONS),
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please select your occupation',
            onChange: (e) => {
              if (e.target.value === 'Other') {
                form.setValue('otherOccupation', '');
                form.clearErrors('otherOccupation');
              }
              if (e.target.value === 'Physician') {
                form.clearErrors('specialty');
              }
            },
          }),
        },
        otherOccupation: {
          label: 'Specify occupation',
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please enter your occupation',
            validate: validateNotEmpty,
          }),
        },
        specialty: {
          label: 'Specialty',
          options: listToOptions(SPECIALTIES),
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please select your specialty',
          }),
        },
        identifier: {
          label: 'Identifier',
          options: hcpIdentifierOptions,
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please select the verification type',
            onChange: () => {
              form.setValue('identifierValue', '');
              form.clearErrors('identifierValue');
            },
          }),
        },
        identifierValue: {
          label:
            hcpIdentifierOptions.length === 1
              ? hcpIdentifierOptions[0].label
              : 'Number',
          placeholder: selectedIdentifier?.placeholder,
          rules: constructRulesFromFieldMetadata({
            required: selectedIdentifierType !== 'fileUpload',
            errorMessage: 'Please enter a valid identifier number',
            pattern: selectedIdentifier?.pattern,
            validate: validateIdentifierValue,
          }),
        },
        referrer: {
          label: 'How did you hear about us?',
          options: listToOptions(HOW_DID_YOU_HEAR),
          rules: constructRulesFromFieldMetadata({
            required: false,
            errorMessage: 'Please select how you heard about us',
          }),
        },
        inviteCode: {
          label: 'Invite Code',
          rules: constructRulesFromFieldMetadata({
            required: false,
            errorMessage: 'Please enter a valid invite code',
          }),
        },
        medicalSchool: {
          label: 'Medical School',
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please enter the name of your medical school',
          }),
        },
        expectedMonthOfGraduationString: {
          label: 'Month',
          options: listToOptions(MONTHS),
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please enter expected month of graduation',
          }),
        },
        expectedYearOfGraduation: {
          label: 'Graduation Year',
          options: listToOptions(YEARS),
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please enter expected year of graduation',
          }),
        },
        files: {
          label: 'Files',
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: fileUploadErrorMessage,
          }),
        },
        rawFiles: {
          label: 'Files',
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: fileUploadErrorMessage,
          }),
        },
        hasConsentedToPolicies: {
          label: '', // Provided in onboarding.tsx template
          rules: constructRulesFromFieldMetadata({
            required: true,
            errorMessage: 'Please read and agree to the policies',
          }),
        },
      } satisfies Record<
        keyof HCPOrStudentFieldValues,
        FieldMetadata<HCPOrStudentFieldValues>
      >),
    [
      form,
      selectedIdentifier,
      selectedIdentifierType,
      fileUploadErrorMessage,
      validateIdentifierValue,
      hcpIdentifierOptions,
    ]
  );

  return {
    // form
    form,

    // functions
    sendMixpanelSignupEventWithReferringPage,
    constructUserDataPayload,
    uploadFiles,

    // helpers
    hcpVerificationExplanationText,
    hcpVerificationHeader,
    hcpVerificationHelpIfNoIDText,
    hasProcessedUser,
    verificationProofTitle,
    verificationProofOptions,

    // default values for dropdowns
    defaultOccupationDropdownValue,
    defaultSpecialtyValue,
    defaultIdentifier,
    defaultReferrerDropdownValue,
    defaultMedicalSchool,
    defaultExpectedMonthOfGraduationString,
    defaultExpectedYearOfGraduation,
    defaultInviteCode,

    // field metadata
    allFieldMetadata,
  };
}
