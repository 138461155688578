import dayjs from 'dayjs';
import { HCP_IDENTIFIERS } from '../identifiers';
import {
  NONNPI_HCP_ACCESS_ROLE_NAME,
  NPI_ACCESS_ROLE_NAME,
  OEAccessRoleName,
  PATIENT_ACCESS_ROLE_NAME,
  STUDENT_ACCESS_ROLE_NAME,
} from '../types';
import {
  HCPOrStudentUserDataPayload,
  PatientUserDataPayload,
  isPatientUserDataPayload,
} from '../users/types';
import { getOEAccessRoleNameFromAccessCode } from './accessCodes';

/* IMPORTANT: Changes in this function should be mirrored in the src/piapiac/xyla/django/openevidence_app/helpers.py file to ensure consistency. */
export function determineAccessLevel(
  auth0Metadata: HCPOrStudentUserDataPayload | PatientUserDataPayload
): OEAccessRoleName | null {
  if (isPatientUserDataPayload(auth0Metadata)) {
    return auth0Metadata.askedDoctor ? PATIENT_ACCESS_ROLE_NAME : null;
  }

  // NB(oliver): Until there is a way to update email address, we will only require uploading verification documents to get access.
  //    Access is given freely, and then removed if the verification does not line up.
  if (
    auth0Metadata.occupation === 'Medical Student' &&
    auth0Metadata.hasVerificationFiles
  ) {
    return STUDENT_ACCESS_ROLE_NAME;
  }

  // NB(ethan): Support for non-npi HCPs who have uploaded verification files.
  //    For now, access is given freely, and then removed if the verification does not line up.
  if (
    auth0Metadata.occupation !== 'Medical Student' &&
    auth0Metadata.hcpIdentifierType === 'fileUpload' &&
    auth0Metadata.hasVerificationFiles
  ) {
    return NONNPI_HCP_ACCESS_ROLE_NAME;
  }

  // If the user has an active access code, grant access to the role associated with the code
  if (auth0Metadata.hcpIdentifierValue?.startsWith('XA-')) {
    return getOEAccessRoleNameFromAccessCode(auth0Metadata.hcpIdentifierValue);
  }

  const identifier = HCP_IDENTIFIERS.find(
    (_identifier) => _identifier.type === auth0Metadata.hcpIdentifierType
  );
  const validatedIdentifierValue = identifier?.validate?.(
    auth0Metadata.hcpIdentifierValue
  );

  if (
    validatedIdentifierValue !== null &&
    validatedIdentifierValue !== undefined
  ) {
    return auth0Metadata.hcpIdentifierType === 'npi'
      ? NPI_ACCESS_ROLE_NAME
      : NONNPI_HCP_ACCESS_ROLE_NAME;
  }

  return null;
}

interface GetAuth0UpdateBodiesProps {
  auth0Metadata: HCPOrStudentUserDataPayload | PatientUserDataPayload;
}

export interface Auth0UpdateBodyParts {
  updateUserBody: {
    user_metadata: {
      [key: string]: any;
    };
  };
  inviteCode: string;
}

export function getAuth0UpdateBodies({
  auth0Metadata,
}: GetAuth0UpdateBodiesProps): Auth0UpdateBodyParts {
  /* When the client is ready to update auth0 data, three things need to happen:
     0. If an access code is given, check that it is valid and account for it properly
     1. Update the user's metadata
     2. (Usually) assign a role to the user based on their metadata 
     (this MUST happen server-side, the user MUST NOT be able to specify a role for themselves client-side)

     Where the server-side call exactly happens is up to the client (web vs. app), but regardless
      of where it happens, the logic should be the same.
  */

  // Construct the update bodies
  return {
    updateUserBody: {
      user_metadata: {
        auth0Metadata,
        hasProcessedUser: true,
        lastUpdatedAt: dayjs().format(),
      },
    },
    inviteCode: auth0Metadata.inviteCode ?? '',
  };
}
