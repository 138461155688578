import { jwtDecode } from 'jwt-decode';
import {
  ACCESS_ROLE_PRECEDENCE,
  OEAccessRoleName,
  PERMISSION_TO_ROLE_NAME_MAP,
} from '../types';
import { UserInfo } from './types';

export const getOEAccessLevelFromPermissionArray = (
  permissions: string[]
): OEAccessRoleName | null => {
  // Determine which access level by iterating through the PERMISSION_TO_ACCESS_LEVEL_MAP keys and checking if the user has the permission
  // and returning the highest level of access that the user has according to ACCESS_ROLE_PRECEDENCE
  // or the first matching role in PERMISSION_TO_ROLE_NAME_MAP
  const matchingOEAccessLevels = Object.entries(PERMISSION_TO_ROLE_NAME_MAP)
    .filter(([permission]) => {
      return permissions.includes(permission);
    })
    .map(([_, roleName]) => roleName);

  if (matchingOEAccessLevels.length === 0) {
    return null;
  }

  return (
    ACCESS_ROLE_PRECEDENCE.find((role) =>
      matchingOEAccessLevels.includes(role)
    ) ?? matchingOEAccessLevels[0]
  );
};

interface GetUserInfoProps {
  // compare to https://auth0.com/docs/manage-users/user-accounts/user-profiles/user-profile-structure#user-profile-attributes
  // also investigated /api/auth/me to see what is being populated in the
  // frontend
  // can probably assert more strongly that these are not undefined, but
  // without a deep dive, I want to be defensive
  auth0User: {
    created_at?: string;
    updated_at?: string;
    email?: string;
    email_verified?: boolean;
    family_name?: string;
    given_name?: string;
    nickname?: string;
    name?: string;
    sub?: string;
    sid?: string;
    picture?: string;
    user_metadata?: { [key: string]: any };
    app_metadata?: { [key: string]: any };
  };
  permissions: string[];
}

// This gets the user info from the auth0 user object and the permissions
// and formats it into the object that the frontend client expects.
// null means the user is not logged in.
// undefined means the user needs to re-log in.
export const getUserInfo = ({
  auth0User,
  permissions,
}: GetUserInfoProps): UserInfo | undefined => {
  // Only one of accessToken or permissions should be passed in
  const oeAccessLevel = getOEAccessLevelFromPermissionArray(permissions);

  // We used to not set the user_metadata in the session, so we need to explicitly re-log users
  // if they don't have the user_metadata.
  // This is a good place to check for requirement for user re-logging in the future.
  if (!auth0User.user_metadata) {
    return undefined;
  }

  // Replace legacy npi, gmcNumber, etc. with the new hcpIdentifierType and hcpIdentifierValue
  const {
    npi,
    gmcNumber,
    minc,
    ihi,
    crmNumber,
    mohLicenseNumber,
    nhsNumber,
    ...rest
  } = auth0User.user_metadata;

  // NOTE: DO NOT ADD TO THIS IF YOU ADD A NEW HCP IDENTIFIER TYPE
  // This is only for legacy support, where user metadata might have the old identifier fields
  if (!rest.hcpIdentifierType || !rest.hcpIdentifierValue) {
    if (npi) {
      rest.hcpIdentifierType = 'npi';
      rest.hcpIdentifierValue = npi;
    } else if (gmcNumber) {
      rest.hcpIdentifierType = 'gmcNumber';
      rest.hcpIdentifierValue = gmcNumber;
    } else if (minc) {
      rest.hcpIdentifierType = 'minc';
      rest.hcpIdentifierValue = minc;
    } else if (ihi) {
      // Legacy field is ihi, but the correct identifier is ahpra
      rest.hcpIdentifierType = 'ahpra';
      rest.hcpIdentifierValue = ihi;
    } else if (crmNumber) {
      rest.hcpIdentifierType = 'crmNumber';
      rest.hcpIdentifierValue = crmNumber;
    } else if (mohLicenseNumber) {
      rest.hcpIdentifierType = 'mohLicenseNumber';
      rest.hcpIdentifierValue = mohLicenseNumber;
    }
  }

  // Display name depends on the default auth0 name field and the user metadata name field
  const auth0NameReal =
    auth0User.name !== auth0User.email ? auth0User.name : undefined;
  const userMetadataName = rest.name;
  const bestDisplayName = auth0NameReal ?? userMetadataName ?? null;

  return {
    ...rest,
    name: bestDisplayName,
    id: auth0User.sub,
    bestDisplayName,
    email: auth0User.email,
    emailVerified: auth0User.email_verified,
    picture: auth0User.picture,
    oeAccessLevel,
  } as UserInfo;
};

// There are other things, but this is the only thing that is used in the frontend
export interface Auth0AccessTokenJWT {
  permissions: string[];
}

export function getPermissionsFromAccessToken(accessToken: string) {
  const decodedToken = jwtDecode<Auth0AccessTokenJWT>(accessToken);
  return decodedToken.permissions;
}

/**
 * Whether this user should be considered an internal user for analytics
 * purposes (i.e. mixpanel).
 *
 * DO NOT USE this for providing any sort of access control or gating new functionality. Instead, consider any of the following
 * - use waffle + django user groups and add user ids
 * - use auth0 roles/permissions
 * - have a separate function that checks emails, but only if they are marked
 *   as email_verified = true
 * @param userInfo
 * @returns
 */
export function isInternalUserForAnalyticsPurposesOnly(
  userInfo: UserInfo | undefined | null
): boolean {
  const email = userInfo?.email;
  if (!email) {
    return false;
  }

  // Treat these emails as external users
  const externalEmails = ['demo@openevidence.com'];
  if (externalEmails.includes(email.toLowerCase())) {
    return false;
  }

  // These substrings are considered internal users for analytics purposes only
  const internalEmailSubstrings = [
    '@xyla.com',
    '@elsevier.com',
    '@openevidence.com',
    'kcazyz',
    'daniel.nadler',
    'travis.zack@ucsf.edu',
  ];
  return internalEmailSubstrings.some((substring) => {
    return email.includes(substring.toLowerCase());
  });
}
