import { SignupCompletedEventProps, TrackMixpanelEvent } from '@xyla/analytics';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { HOW_DID_YOU_HEAR } from '../constants';
import { PATIENT_OR_CAREGIVER_REGISTRATION_TYPE } from '../types';
import { PatientUserDataPayload, UserInfo } from '../users/types';
import {
  PATIENT_COMMUNITIES,
  PT_SIGNUP_ROLES,
  PatientCommunity,
  PatientFieldValues,
} from './fieldValues';
import { FieldMetadata } from './types';
import { SendMixpanelSignupEventPropsWithReferrer } from './useOnboardingBase';
import {
  constructRulesFromFieldMetadata,
  listToOptions,
  validateNotEmpty,
} from './utils';

interface UseOnboardingPatientProps {
  userInfo: UserInfo;
  trackMixpanelEvent: TrackMixpanelEvent;
}

export function useOnboardingPatient({
  userInfo,
  trackMixpanelEvent,
}: UseOnboardingPatientProps) {
  const patientCommunityOptions = useMemo(
    () => Object.keys(PATIENT_COMMUNITIES),
    []
  );

  const defaultRole = useMemo(
    () =>
      PT_SIGNUP_ROLES.includes(userInfo.role ?? '') ? userInfo.role ?? '' : '',
    [userInfo]
  );

  const defaultPatientCommunity = useMemo(
    () =>
      patientCommunityOptions.includes(userInfo?.patientCommunity ?? '')
        ? userInfo?.patientCommunity ?? ''
        : '',
    [userInfo, patientCommunityOptions]
  );

  const defaultReferrerDropdownValue = useMemo(
    () =>
      HOW_DID_YOU_HEAR.includes(userInfo?.referrer ?? '')
        ? userInfo?.referrer ?? ''
        : '',
    [userInfo]
  );

  const defaultPatientCommunitySubtype = useMemo(
    () =>
      (defaultPatientCommunity
        ? PATIENT_COMMUNITIES[defaultPatientCommunity as PatientCommunity]
        : []
      ).includes(userInfo?.patientCommunitySubtype ?? '')
        ? userInfo?.patientCommunitySubtype
        : '',
    [userInfo, defaultPatientCommunity]
  );

  const defaultValues = useMemo(
    () => ({
      name: userInfo?.name ?? '',
      role: defaultRole,
      patientCommunity: defaultPatientCommunity as PatientCommunity,
      referrer: defaultReferrerDropdownValue,
      patientCommunitySubtype: defaultPatientCommunitySubtype,
    }),
    [
      userInfo,
      defaultRole,
      defaultPatientCommunity,
      defaultReferrerDropdownValue,
      defaultPatientCommunitySubtype,
    ]
  );

  const form = useForm<PatientFieldValues>({
    defaultValues,
  });
  const selectedPatientCommunity = form.watch('patientCommunity');

  // If the source data ever changes from outside of here, reset the form.
  // This is needed because in the app, we don't start off with the data from a "server"
  // the way we do on the web.
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const constructUserDataPayload = useCallback((data: PatientFieldValues) => {
    const body: PatientUserDataPayload = {
      name: data.name.trim(),
      role: data.role,
      patientCommunity: data.patientCommunity,
      askedDoctor: data.askedDoctor,
      patientCommunitySubtype: data.patientCommunitySubtype,
      registrationType: PATIENT_OR_CAREGIVER_REGISTRATION_TYPE,
      referrer: data.referrer,
      hasConsentedToPolicies: data.hasConsentedToPolicies,
    };

    return body;
  }, []);

  const sendMixpanelSignupEventWithReferringPage = useCallback(
    async ({
      body,
      referringPage,
      accessLevelToGive,
      hasProcessedUser,
    }: SendMixpanelSignupEventPropsWithReferrer<PatientUserDataPayload>) => {
      await trackMixpanelEvent?.('signup_completed_trusted', {
        // patient specific props
        role: body.role,
        patientCommunity: body.patientCommunity,
        askedDoctor: body.askedDoctor,
        patientCommunitySubtype: body.patientCommunitySubtype,

        // explicitly overwrite props that are sent on every event
        auth0AccessLevel: accessLevelToGive ?? undefined,
        auth0RegistrationType: body.registrationType,

        // misc/accounting
        referring_page: referringPage,
        skippingMetadataBecauseKnown: false,
        hasProcessedUser,
        referrer: body.referrer,
        prioritizeEventAuth0Data: true, // Need this here because we have new knowledge that is not baking into the session yet
        hasConsentedToPolicies: body.hasConsentedToPolicies,
      } satisfies SignupCompletedEventProps);
    },
    [trackMixpanelEvent]
  );

  const hasProcessedUser = useMemo(
    () => userInfo?.hasProcessedUser ?? false,
    [userInfo]
  );

  const patientCommunitySubtypeErrorMessage = useMemo(
    () => `Please select the type of ${selectedPatientCommunity.toLowerCase()}`,
    [selectedPatientCommunity]
  );

  const patientCommunitySubtypeOptions = useMemo(
    () =>
      listToOptions(
        selectedPatientCommunity
          ? PATIENT_COMMUNITIES[selectedPatientCommunity]
          : []
      ),
    [selectedPatientCommunity]
  );

  const allFieldMetadata: Record<
    keyof PatientFieldValues,
    FieldMetadata<PatientFieldValues>
  > = useMemo(
    () => ({
      name: {
        label: 'Name',
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please enter your name',
          validate: validateNotEmpty,
        }),
      },
      role: {
        label: 'Role',
        options: listToOptions(PT_SIGNUP_ROLES),
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please select your role',
        }),
      },
      patientCommunity: {
        label: 'Patient Community',
        options: listToOptions(patientCommunityOptions),
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please select your patient community',
          onChange: () => {
            form.setValue('patientCommunitySubtype', '');
            form.clearErrors('patientCommunitySubtype');
          },
        }),
      },
      patientCommunitySubtype: {
        label: 'Type',
        options: patientCommunitySubtypeOptions,
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: patientCommunitySubtypeErrorMessage,
        }),
      },
      askedDoctor: {
        label:
          'I have talked to (or will soon talk to) my doctor about using OpenEvidence.',
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage:
            'You must talk to your doctor before using OpenEvidence',
        }),
      },
      referrer: {
        label: 'How did you hear about us?',
        options: listToOptions(HOW_DID_YOU_HEAR),
        rules: constructRulesFromFieldMetadata({
          required: false,
          errorMessage: 'Please select how you heard about us',
        }),
      },
      hasConsentedToPolicies: {
        label: '', // Provided in onboarding-patients.tsx template
        rules: constructRulesFromFieldMetadata({
          required: true,
          errorMessage: 'Please read and agree to the policies',
        }),
      },
    }),
    [
      form,
      selectedPatientCommunity,
      patientCommunitySubtypeErrorMessage,
      patientCommunitySubtypeOptions,
      patientCommunityOptions,
    ]
  );

  return {
    // form
    form,

    // functions
    sendMixpanelSignupEventWithReferringPage,
    constructUserDataPayload,

    // helpers
    hasProcessedUser,

    // default values for dropdowns
    defaultRole,
    defaultPatientCommunity,
    defaultReferrerDropdownValue,
    defaultPatientCommunitySubtype,

    // field metadata
    allFieldMetadata,
  };
}
