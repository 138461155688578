import { Fragment } from 'react';
import { Link } from 'react-scroll';
import clsx from 'clsx';

import type { ArticleData, Citation, LegacyCitation } from './types';
import { isRichCitation } from './utils';

// When we scroll to an element, offset global header height
export const CITATION_SCROLL_OFFSET = -100;

// Suffix used to distinguish citation/references from other react-scroll links
export const CITATION_LINK_SUFFIX = '_refLink';

export class CtrlLink extends Link {
  handleClick = (event: any) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(window.location.pathname + '#' + this.props.to, '_blank');
    } else {
      (this as any).scrollTo(this.props.to, this.props);
    }
  };
}

/** Returns the full citation content as a single string */
export function getPlainCitationString(reference: Citation): string {
  if (typeof reference === 'string') {
    return reference;
  } else {
    return reference.citation;
  }
}

/** Array.prototype.findIndex for Citation arrays */
export function findCitationIndex(arr: Citation[], citation: Citation): number {
  const searchString = getPlainCitationString(citation);
  return arr.findIndex((el) => getPlainCitationString(el) === searchString);
}

/** Dedupe citations in article and build the corresponding references list in order. */
export function buildReferencesListFromArticle(
  article: ArticleData
): Citation[] {
  const references: Citation[] = [];

  // "Upsert" the citation into the references list
  function processCitation(citation: Citation) {
    const citationIndex = findCitationIndex(references, citation);
    if (citationIndex === -1) {
      // Compute snippet text with highlights
      const normalizedCitation: Citation =
        isRichCitation(citation) && citation.metadata.snippet_text
          ? {
              ...citation,
              all_snippet_display_data: [
                {
                  text: citation.metadata.snippet_text,
                  critical_sentences: citation.metadata.critical_sentences,
                  image_data: citation.metadata.citation_detail.image_data,
                },
              ],
            }
          : citation;

      references.push(normalizedCitation);
    } else {
      // We've already found this citation.
      // If it is a rich citation that has different snippet text, add it to the list of snippet text
      const existingCitation = references[citationIndex];
      let newCitation: Citation = existingCitation;
      if (isRichCitation(citation) && isRichCitation(existingCitation)) {
        // Check if the snippet text is already in the list
        const newSnippetText = citation.metadata.snippet_text;
        if (newSnippetText) {
          const newCriticalSentences = citation.metadata.critical_sentences;
          if (
            !existingCitation.all_snippet_display_data?.some(
              (snippet) => snippet.text === newSnippetText
            )
          ) {
            // The snippet text is not already in the list or the list does not exist
            const snippetDisplayData = {
              text: newSnippetText,
              critical_sentences: newCriticalSentences,
              image_data: citation.metadata.citation_detail.image_data,
            };

            // Make the new list
            const newSnippetDisplayDataArray =
              existingCitation.all_snippet_display_data
                ? [
                    ...existingCitation.all_snippet_display_data,
                    snippetDisplayData,
                  ]
                : [snippetDisplayData];

            // Make the new citation
            newCitation = {
              ...existingCitation,
              all_snippet_display_data: newSnippetDisplayDataArray,
            };
          }
        }

        // Update the existing citation
        references[citationIndex] = newCitation;
      }
    }
  }

  for (let section of article.articlesection_set) {
    for (let paragraph of section.articleparagraph_set) {
      for (let articleSpan of paragraph.articlespan_set) {
        for (let citation of articleSpan.citations) {
          processCitation(citation);
        }
      }
    }
  }

  return references;
}

export function ct_to_citation_string(ct: LegacyCitation): string {
  let pubmed_url = 'https://pubmed.ncbi.nlm.nih.gov/' + ct.pmid;
  let title_string = `<a target="_blank" href="${pubmed_url}">${ct.title}</a>`;
  let citation_string = ct.citation.replace(ct.title, title_string);
  return citation_string;
}

export function CitationSpan({
  citations,
  references,
  prefix,
  styles,
  disableLinks = false,
  questionIndex,
  askWrapperContainerId,
}: {
  /** The citations that we need to display in this span, e.g. `[1][3-4]` */
  citations: Citation[];
  /** All available references in the article */
  references: Citation[];
  prefix: string;
  styles: Record<string, string>;

  // Set to true to disable links to references
  disableLinks?: boolean;

  questionIndex?: number;
  askWrapperContainerId?: string;
}) {
  let prev_start: number | null = null;
  let last_number: number | null = null;
  const citation_elements: JSX.Element[] = [];

  function end_last_bracket() {
    if (last_number === null || prev_start === null) return;
    let ref_str = null;
    if (last_number - prev_start > 0)
      ref_str = `[${prev_start}-${last_number}]`;
    else ref_str = `[${prev_start}]`;

    const key = `${prefix}_${prev_start}_citelink`;

    let elementLink = disableLinks ? (
      <Fragment key={key}>{ref_str}</Fragment>
    ) : (
      <CtrlLink
        key={key}
        to={`${questionIndex}_${prev_start}${CITATION_LINK_SUFFIX}`}
        offset={CITATION_SCROLL_OFFSET}
        containerId={askWrapperContainerId}
      >
        {ref_str}
      </CtrlLink>
    );
    citation_elements.push(elementLink);
  }

  for (let i = 0; i < citations.length; i++) {
    // The number for this citation that will be shown in the UI
    const number = findCitationIndex(references, citations[i]) + 1;

    let end_last = false;
    let start_new = false;
    if (last_number == null) {
      start_new = true;
    } else if (number - last_number > 1) {
      start_new = true;
      end_last = true;
    }

    // Handle ending a bracket
    if (end_last) {
      end_last_bracket();
    }
    // Handle starting a new bracket
    if (start_new) {
      prev_start = number;
    }
    last_number = number;
  }
  if (prev_start != null) end_last_bracket();

  return (
    <span
      key={`${prefix}_${prev_start}_citelink`}
      className={clsx(
        disableLinks ? styles.citations_disabled : styles.citations,
        'brandable--citation'
      )}
    >
      {citation_elements}
    </span>
  );
}
